<template>
  <div class="auth-form-wrapper">
    <div class="flex justify-content-center auth-wrapper">
      <div class="card">
        <h5 class="text-center">Login</h5>
        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">

          <!-- Email -->
          <div class="field">
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-envelope"/>
              <InputText
                id="email" v-model="v$.form.email.$model"
                :class="{'p-invalid':v$.form.email.$invalid && submitted}" aria-describedby="email-error"
              />
              <label for="email" :class="{'p-error':v$.form.email.$invalid && submitted}">Email*</label>
            </div>

            <span v-if="v$.form.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.form.email.$errors" :key="index">
                <small class="p-error">{{ error.$message.replace('Value', 'Email') }}</small>
              </span>
            </span>
            <small
              v-else-if="(v$.form.email.$invalid && submitted) || v$.form.email.$pending.$response"
              class="p-error">{{ v$.form.email.required.$message.replace('Value', 'Email') }}
            </small>
          </div>

          <!-- Password -->
          <div class="field">
            <div class="flex justify-content-end">
              <router-link :to="'/forgot-password'">
                <small>Forgot Password?</small>
              </router-link>
            </div>
            <div class="p-float-label">
              <Password
                id="password" v-model="v$.form.password.$model" :feedback="false"
                :class="{'p-invalid':v$.form.password.$invalid && submitted}" toggleMask
              />
              <label for="password" :class="{'p-error':v$.form.password.$invalid && submitted}">Password*</label>
            </div>

            <span v-if="v$.form.password.$error && submitted">
              <span v-for="(error, index) of v$.form.password.$errors" :key="index">
                <small class="p-error">{{ error.$message.replace('Value', 'Password') }}</small>
              </span>
            </span>
            <small
              v-else-if="(v$.form.password.$invalid && submitted) || v$.form.password.$pending.$response"
              class="p-error">{{ v$.form.password.required.$message.replace('Value', 'Password') }}
            </small>
          </div>

          <Button type="submit" label="Sign In" class="mt-2"/>
          <div class="flex justify-content-center p-3">- OR -</div>
          <Button type="button" label="Sign Up" class="mt-2" @click="GoSignUp"/>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {email, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {mapActions} from "vuex";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import errorMessageBuilder from "@/builders/ErrorMessageBuilder";
import store from "@/store";
import user from '@/models/User';

export default {
  setup: () => ({v$: useVuelidate()}),

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      submitted: false,
      showMessage: false
    }
  },

  validations() {
    return {
      form: {
        email: {required, email},
        password: {required},
      }
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      user: 'auth/user',
    }),

    GoSignUp() {
      this.$router.push({name: 'register'});
    },

    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.login(this.form)
        .then(() => {
          this.getUser();
        })
        .catch((error) => {
          this.$toast.add(toastParamBuilder.error(error.response?.data?.message));
        });
    },

    getUser() {
      this.user()
        .then(() => {
          this.resetForm();
          this.$router.push({name: this.getRedirectName()});
        })
        .catch((error) => {
          this.$toast.add(toastParamBuilder.error(errorMessageBuilder(error)));
        });
    },

    resetForm() {
      this.form.email = '';
      this.form.password = '';
      this.submitted = false;
    },

    getRedirectName() {
      if (store.getters['auth/getRole'] === user.ROLE_SUPER_ADMIN) {
        return 'dashboard';
      }

      return 'orders';
    }
  }
}
</script>
